
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { Actions, Getters } from '@/store/enums/TenantEnums';
import moment from 'moment';
import toasts from '@/utils/toasts';
import FormSendReferenceReport from '@/views/v2/views/components/FormSendReferenceReport.vue';
import {
  Getters as TenantReviewGetters,
  Actions as TenantReviewActions,
} from '@/store/enums/TenantReviewEnums';
import PDFGenerator from '@/views/v2/views/components/PDFGenerator.vue';
import LedgerReport from '@/views/v2/views/pages/Report/Ledger.vue';
import { TenantReview } from '@/models/TenantReviewModel';
import { numberFormat } from '@/utils/text';
import image from '@/utils/image';
import DownloadReportCard from '@/views/v2/views/components/DownloadReportCard.vue';

export default defineComponent({
  components: {
    FormSendReferenceReport,
    PDFGenerator,
    LedgerReport,
    DownloadReportCard,
  },
  data: () => {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Tenant Name', value: 'name' },
          { text: 'Submitted By', value: 'agent' },
          { text: 'Action', value: 'action' },
        ],
        items: [],
      },
      averageRating: '0',
      googleMapImage: '',
      sendReport: false,
      sendingReport: false,
    };
  },
  methods: {
    ...mapActions({
      searchTenantRef: Actions.SEARCH_TENANT,
      fetchTenantReviews: TenantReviewActions.FETCH_ALL_TENANT_REVIEWS,
      sendToEmails: TenantReviewActions.SEND_TENANT_REVIEW_TO_EMAILS,
      sendEmptyReportToEmails: TenantReviewActions.SEND_EMPTY_REPORT_TO_EMAILS,
    }),
    submitReport() {
      this.$router.push('/tenant/overview');
    },
    requestTenant() {
      this.$router.push('/tenant/request');
    },
    async emailReport(emails) {
      if (this.sendingReport) return;
      this.sendingReport = true;

      const refNo = this.currentTenantReview?.reference_no
        ? this.currentTenantReview?.reference_no
        : null;

      const payload = {
        refNo: refNo,
        emails: emails,
      };

      return await (refNo !== null
        ? this.sendToEmails
        : this.sendEmptyReportToEmails)(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          (this.$refs.emailReport as any).clear();
        })
        .catch(() => {
          toasts.error({}, 'Something went wrong on sending the report.');
        })
        .finally(() => {
          this.sendingReport = false;
          this.sendReport = false;
        });
    },
    computeAverageRating() {
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        let sumRating = 0;
        this.tenantReviews.map((review: TenantReview) => {
          sumRating = sumRating + parseFloat(review.average_rating);
        }, 0);

        const average = `${sumRating / this.tenantReviews.length}`;
        this.averageRating = numberFormat(average);
      }
    },
    async getGoogleMapImageSrc() {
      this.googleMapImage = (this.currentTenantReview as any)?.map_static_src;
    },
    async generatePDF() {
      await (this.$refs.pdfReport as any).generatePDf();
    },
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      searhTenantErrors: Getters.GET_SEARCH_TENANT_ACTION_ERROR,
      allTenants: Getters.GET_ALL_TENANTS,
      tenants: Getters.GET_SEARCH_TENANT_LIST,
      tenantReviews: TenantReviewGetters.GET_ALL_TENANT_REVIEWS,
    }),
    sortedReviewsDesc() {
      if (this.tenantReviews.length) {
        let reviews = this.tenantReviews as any;
        reviews.sort(function (a, b) {
          return (
            (new Date(b.date_created) as any) -
            (new Date(a.date_created) as any)
          );
        });

        return reviews;
      }

      return [];
    },
    currentTenantReview() {
      if (this.sortedReviewsDesc.length) {
        return this.sortedReviewsDesc[0];
      }
      return null;
    },
    googleStaticMapImageSrc() {
      const src = (this.currentTenantReview as any)?.map_static_src;
      return src && src != '' ? src : require('@/assets/images/map.png');
    },
  },
  async mounted() {
    await this.fetchTenantReviews();
    this.computeAverageRating();
  },
});
