
import { defineComponent } from 'vue';
import InputTag from '@/views/v2/views/components/InputTag.vue';
import { email as emailRegex } from '@/utils/regex';

export default defineComponent({
  components: { InputTag },
  props: {
    loading: { default: false }
  },
  data() {
    return {
      emails: [],
      sending: false,
    };
  },
  computed: {
    emailValidRegex() {
      return emailRegex;
    },
  },
  emits: ['send'],
  methods: {
    submit() {
      this.$emit('send', this.emails);
    },
    clear() {
      this.emails = [];
    },
  },
});
