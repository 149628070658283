
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isDownloaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emails: [],
      loading: false,
    };
  },
  emits: ['download'],
  methods: {
    download() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.$emit('download');
      }, 2000);
    },
  },
  watch: {
    isDownloaded(value) {
      if (value) {
        this.loading = value;
      }
    },
  },
});
